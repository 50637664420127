import React from 'react';
import '../styles/About.css';
import camelaSpeaking from '../assets/HARRIS.jpg'; // Make sure this image is in your assets folder
// import camelaCoin from '../assets/dashboard_component.png'; // Make sure this image is in your assets folder

const About = () => {
  return (
    <section id="about" className="about">
      <div className="about-background"></div>
      <div className="about-content">
        <h2 className="section-title">About Camela</h2>
        <div className="about-grid">
          <div className="about-text">
            <p className="lead">Meet Camela, the trailblazing camel with a vision to lead America to new heights... or should we say humps?</p>
            <p>As the world's first camel candidate for the highest office in the land, Camela is here to prove that sometimes, the best person for the job isn't a person at all!</p>
          </div>
          <div className="camela-speaking">
            <img src={camelaSpeaking} alt="Camela speaking" />
            <blockquote>"I promise to make America hump again, one dune at a time!"</blockquote>
          </div>
        </div>
        
        <h3 className="subsection-title">Why Camela?</h3>
        <div className="about-grid">
          <div className="about-text">
            <p>$Camela isn't just another cryptocurrency – it's a movement! By investing in Camela Coin, you're not just filling your digital wallet; you're filling the nation's water reserves with hope, humor, and yes, a bit of sand.</p>
          </div>
          <div className="coin-display">
            <p className="cta-text">Join the caravan and be part of the most hilarious political revolution since sliced pita!</p>
            <button className="cta-button">Join the Movement</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;