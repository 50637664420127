import React from 'react';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import About from './components/About';
import Tokenomics from './components/Tokenomics';
import Community from './components/Community';
import Footer from './components/Footer';
import './App.css';

function App() {
  return (
    <div className="App">
      <Header />
      <HeroSection />
      <About />
      <Tokenomics />
      <Community />
      <Footer />
      {/* We'll add other components here as we create them */}
    </div>
  );
}

export default App;