import React from 'react';
import '../styles/HeroSection.css';
import camelaImage from '../assets/hero.jpg';

const HeroSection = () => {
  return (
    <section className="hero">
      <div className="hero-background"></div>
      <div className="hero-content">
        <h1>Make America<br /><span className="highlight">Hump Again!</span></h1>
        <p>Join Camela on the journey to the Oval Oasis</p>
        <button className="cta-button">Join the Caravan</button>
      </div>
      <div className="hero-image">
        <img src={camelaImage} alt="Camela for President" />
      </div>
    </section>
  );
};

export default HeroSection;