import React from 'react';
import '../styles/Tokenomics.css';
import tokenomicsImage from '../assets/TRUMP.jpg'; // Make sure this image is in your assets folder

const Tokenomics = () => {
  return (
    <section id="tokenomics" className="tokenomics">
      <div className="tokenomics-background"></div>
      <div className="tokenomics-content">
        <h2 className="section-title">Tokenomics</h2>
        <div className="tokenomics-grid">
          <div className="tokenomics-info">
            <h3 className="subsection-title">Coin Distribution</h3>
            <ul className="distribution-list">
              <li><span className="highlight">50%</span> Public Sale (Because democracy!)</li>
              <li><span className="highlight">20%</span> Campaign Fund (For those sandy rallies)</li>
              <li><span className="highlight">15%</span> Development (Building oases in the blockchain desert)</li>
              <li><span className="highlight">10%</span> Team (Camel treats, mainly)</li>
              <li><span className="highlight">5%</span> Reserve (For unexpected sandstorms)</li>
            </ul>
            <h3 className="subsection-title">Camel-nomics</h3>
            <p>Total Supply: <span className="highlight">1,000,000,000 CAMELA</span></p>
            <p>Ticker: <span className="highlight">$CAMELA</span></p>
            <p>Blockchain: Solana</p>
          </div>
          <div className="tokenomics-image">
            <img src={tokenomicsImage} alt="Camela Tokenomics" />
          </div>
        </div>
        <div className="unique-features">
          <h3 className="subsection-title">Unique Features</h3>
          <ul className="features-list">
            <li><strong>Desert Oasis Staking:</strong> Stake your $CAMELA and watch your oasis grow!</li>
            <li><strong>Sandstorm Burn:</strong> Regular token burns to create scarcity, just like water in the desert.</li>
            <li><strong>Camel Caravan Rewards:</strong> Earn rewards for holding through our innovative "hodl-in-the-hump" mechanism.</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Tokenomics;