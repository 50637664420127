import React, { useState } from 'react';
import '../styles/Header.css';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="logo">
        <img src="/logo.png" alt="Camela Logo" />
      </div>
      <button className="menu-toggle" onClick={toggleMenu}>
        <span className="sr-only">Toggle menu</span>
        <div className="hamburger">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>
      <nav className={`main-nav ${isMenuOpen ? 'is-open' : ''}`}>
        <ul>
          <li><a href="#about" onClick={toggleMenu}>About</a></li>
          <li><a href="#tokenomics" onClick={toggleMenu}>Tokenomics</a></li>
          {/* <li><a href="#roadmap" onClick={toggleMenu}>Roadmap</a></li> */}
          <li><a href="#community" onClick={toggleMenu}>Community</a></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;