import React from 'react';
import '../styles/Footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <h3>CAMELA</h3>
          <p>Make America Hump Again</p>
        </div>
        <div className="footer-links">
          <a href="#about">About</a>
          <a href="#tokenomics">Tokenomics</a>
          <a href="#community">Community</a>
        </div>
        <div className="footer-contact">
          <p>info@camelacoin.com</p>
          <p>123 Dune St, Sahara Desert</p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {currentYear} Camela Coin | All rights reserved</p>
        <p className="disclaimer">This is a memecoin. Invest responsibly.</p>
      </div>
    </footer>
  );
};

export default Footer;