import React from 'react';
import '../styles/Community.css';
import camelaImage from '../assets/camela.jpg';
import twitterIcon from '../assets/twitter.svg';
import telegramIcon from '../assets/telegram.png';
import dexscreenerIcon from '../assets/dexscreener.png';

const Community = () => {
  return (
    <section id="community" className="community">
      <div className="community-background"></div>
      <div className="community-content">
        <h2 className="section-title">Join the Camel Caravan</h2>
        <div className="social-links">
          <h3 className="subsection-title">Follow Camela's Campaign Trail</h3>
          <div className="social-icons">
            <a href="https://x.com/camelaharris_" target="_blank" rel="noopener noreferrer" className="social-icon">
              <img src={twitterIcon} alt="Twitter" />
              <span>X</span>
            </a>
            <a href="https://t.me/camelaharrissol" target="_blank" rel="noopener noreferrer" className="social-icon">
              <img src={telegramIcon} alt="Telegram" />
              <span>Telegram</span>
            </a>
            <a href="https://dexscreener.com" target="_blank" rel="noopener noreferrer" className="social-icon">
              <img src={dexscreenerIcon} alt="Dexscreener" />
              <span>Dexscreener</span>
            </a>
          </div>
        </div>
        <div className="community-image">
          <img src={camelaImage} alt="Camela Community" />
          <p className="image-caption">"Together, we'll make waves in the political desert!"</p>
        </div>
      </div>
    </section>
  );
};

export default Community;